.pllexislider {
  margin: 0;
  padding: 0;
  direction: ltr !important;
}
.pllexislider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}
.pllexislider .slides img {
  width: 100%;
  display: block;
}
.pllexislider .slides:after {
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.pllex-pauseplay span {
  text-transform: capitalize;
}
.pllexislider,
.pllexislider .slides li,
.pllexislider .slides > li > a {
  height: 100%;
  padding: 0 !important;
  background: none;
  margin: 0;
  border: none;
}
.pllexislider,
.pllexislider .slides li:hover,
.pllexislider .slides > li:hover > a {
  border: none;
}
.jr-insta-thumb .no-bullet,
.pllexislider .no-bullet {
  list-style: none;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  clear: both;
}
.pllexislider {
  margin: 0;
  position: relative;
}
.pllex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.loading .pllex-viewport {
  max-height: 300px;
}
.pllexislider .slides {
  zoom: 1;
  overflow: hidden;
}
.pllexislider .pllex-control-nav {
  z-index: 2;
  position: absolute;
  top: 10px;
  right: 0;
  padding: 0 15px;
  margin-right: auto;
  margin-left: auto;
}
.pllexislider-overlay .pllex-control-nav {
  display: none;
}
.pllexislider .pllex-control-nav li {
  margin: 0 3px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.pllexislider .pllex-control-paging li a {
  width: 11px;
  border: none;
  height: 11px;
  display: block;
  background: #fff;
  background: rgba(255, 255, 255, 255, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
.pllexislider .pllex-control-paging li a:hover,
.pllexislider .pllex-control-paging li a.pllex-active {
  background: rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
}
.pllexislider .pllex-control-paging li a.pllex-active {
  cursor: default;
}
.pllexislider .pllex-control-paging {
  width: 100%;
  text-align: center;
}
.pllexislider .pllex-direction-nav {
  z-index: 2;
  position: absolute;
  list-style: none;
  padding: 0;
  top: 7px;
  right: 2%;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-border-radius: 15%;
  -moz-border-radius: 15%;
  -o-border-radius: 15%;
  border-radius: 15%;
}
.pllexislider-overlay .pllex-direction-nav {
  display: none;
}
.pllexislider .pllex-direction-nav li a {
  line-height: normal;
  overflow: hidden;
  display: block;
  border: 0;
}
.pllexislider .pllex-direction-nav li a:focus {
  outline: 0;
}
.pllexislider .pllex-direction-nav li:first-child {
  float: left;
  margin-right: 0;
  border-left: 0;
  border-right: 1px solid gray;
}
.pllexislider .pllex-direction-nav li {
  list-style: none;
  float: right;
  margin: 0;
  border-right: 0;
  border-left: 1px solid #1e1e1e;
  padding: 0;
  border-top: 0;
  border-bottom: 0;
}
.pllexislider .pllex-direction-nav .pllex-next:before,
.pllexislider .pllex-direction-nav .pllex-prev:before {
  font-family: Arial, Helvetica, sans-serif;
  display: inline-block;
  padding: 0 4px 1px;
  speak: none;
  color: #fff;
  font-size: 18px;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 1);
}
.pllexislider .pllex-direction-nav .pllex-next:before {
  content: "\25BA";
}
.pllexislider .pllex-direction-nav .pllex-prev:before {
  content: "\25C4";
}
.pllexislider-normal ul li .jr-insta-datacontainer {
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
}
.pllexislider-normal ul li .jr-insta-datacontainer .jr-insta-username {
  text-decoration: none;
  display: inline-block;
}
.pllexislider-normal ul li .jr-insta-datacontainer .jr-insta-time {
  float: right;
  display: inline-block;
  text-align: right;
}
.pllexislider-normal ul li .jr-insta-datacontainer .jr-insta-caption {
  line-height: normal;
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
  clear: both;
}
.pllexislider-overlay a {
  color: #fff;
  text-decoration: none;
}
.pllexislider-overlay ul li .jr-insta-wrap {
  position: relative;
  color: #fff;
  line-height: normal;
}
.pllexislider-overlay ul li .jr-insta-wrap .jr-insta-datacontainer {
  position: absolute;
  display: none;
  left: 0;
  bottom: 0;
  width: 100%;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background: rgba(0, 0, 0, 0.4);
  padding: 5px 15px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.pllexislider-overlay
  ul
  li
  .jr-insta-wrap
  .jr-insta-datacontainer
  .jr-insta-username
  a {
  color: #fff !important;
  text-decoration: none;
  font-weight: bold;
}
.pllexislider-overlay
  ul
  li
  .jr-insta-wrap
  .jr-insta-datacontainer
  .jr-insta-time {
  float: right;
  text-align: right;
}
.pllexislider-overlay
  ul
  li
  .jr-insta-wrap
  .jr-insta-datacontainer
  .jr-insta-caption {
  line-height: normal;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
}
.jr-insta-thumb ul.thumbnails {
  list-style: none;
  font-size: 0;
  margin-left: -2.5%;
}
.jr-insta-thumb ul.thumbnails.no-border {
  margin-left: 0;
}
.jr-insta-thumb ul.thumbnails li {
  display: inline-block;
  padding: 0;
  margin: 0 0 2.5% 2.5%;
  background: none;
  border: 0;
  vertical-align: top;
  box-shadow: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.jr-insta-thumb ul.thumbnails.no-border li {
  margin: 0;
}
.jr-insta-thumb ul.thumbnails li img {
  max-width: 100% !important;
  height: auto !important;
  margin: 0 !important;
  border: 0 !important;
  vertical-align: top;
}
.jr-insta-thumb ul.thumbnails li a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.jr-insta-thumb ul.thumbnails.jr_col_1 li {
  width: 97.5% !important;
}
.jr-insta-thumb ul.thumbnails.jr_col_2 li {
  width: 47.5% !important;
}
.jr-insta-thumb ul.thumbnails.jr_col_3 li {
  width: 30.83% !important;
}
.jr-insta-thumb ul.thumbnails.jr_col_4 li {
  width: 22.5% !important;
}
.jr-insta-thumb ul.thumbnails.jr_col_5 li {
  width: 17.5% !important;
}
.jr-insta-thumb ul.thumbnails.jr_col_6 li {
  width: 14.17% !important;
}
.jr-insta-thumb ul.thumbnails.jr_col_7 li {
  width: 11.78% !important;
}
.jr-insta-thumb ul.thumbnails.jr_col_8 li {
  width: 10% !important;
}
.jr-insta-thumb ul.thumbnails.jr_col_9 li {
  width: 8.61% !important;
}
.jr-insta-thumb ul.thumbnails.jr_col_10 li {
  width: 7.5% !important;
}
.jr-insta-thumb ul.thumbnails.no-border.jr_col_1 li {
  width: 100% !important;
}
.jr-insta-thumb ul.thumbnails.no-border.jr_col_2 li {
  width: 50% !important;
}
.jr-insta-thumb ul.thumbnails.no-border.jr_col_3 li {
  width: 33.33% !important;
}
.jr-insta-thumb ul.thumbnails.no-border.jr_col_4 li {
  width: 25% !important;
}
.jr-insta-thumb ul.thumbnails.no-border.jr_col_5 li {
  width: 20% !important;
}
.jr-insta-thumb ul.thumbnails.no-border.jr_col_6 li {
  width: 16.66% !important;
}
.jr-insta-thumb ul.thumbnails.no-border.jr_col_7 li {
  width: 14.28% !important;
}
.jr-insta-thumb ul.thumbnails.no-border.jr_col_8 li {
  width: 12.5% !important;
}
.jr-insta-thumb ul.thumbnails.no-border.jr_col_9 li {
  width: 11.11% !important;
}
.jr-insta-thumb ul.thumbnails.no-border.jr_col_10 li {
  width: 10% !important;
}
@media all and (max-width: 640px) {
  .jr-insta-thumb ul.thumbnails.jr_col_3 li,
  .jr-insta-thumb ul.thumbnails.jr_col_4 li,
  .jr-insta-thumb ul.thumbnails.jr_col_5 li,
  .jr-insta-thumb ul.thumbnails.jr_col_6 li {
    width: 47.5% !important;
  }
  .jr-insta-thumb ul.thumbnails.jr_col_7 li,
  .jr-insta-thumb ul.thumbnails.jr_col_8 li,
  .jr-insta-thumb ul.thumbnails.jr_col_9 li,
  .jr-insta-thumb ul.thumbnails.jr_col_10 li {
    width: 22.5% !important;
  }
  .jr-insta-thumb ul.thumbnails.no-border.jr_col_3 li,
  .jr-insta-thumb ul.thumbnails.no-border.jr_col_4 li,
  .jr-insta-thumb ul.thumbnails.no-border.jr_col_5 li,
  .jr-insta-thumb ul.thumbnails.no-border.jr_col_6 li {
    width: 50% !important;
  }
  .jr-insta-thumb ul.thumbnails.no-border.jr_col_7 li,
  .jr-insta-thumb ul.thumbnails.no-border.jr_col_8 li,
  .jr-insta-thumb ul.thumbnails.no-border.jr_col_9 li,
  .jr-insta-thumb ul.thumbnails.no-border.jr_col_10 li {
    width: 25% !important;
  }
}
@media all and (max-width: 480px) {
  .jr-insta-thumb ul.thumbnails li {
    width: 100% !important;
  }
}
.graphdiv {
  -webkit-box-align: end;
  align-items: flex-end;
  bottom: 0;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}
.GraphImage {
  position: relative;
}
.GraphSidecar {
  position: relative;
}
.GraphVideo {
  position: relative;
}
.GraphImage:before {
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  background-position: -32px -32px;
  background-color: transparent !important;
  height: 32px;
  width: 32px;
  top: 5px;
  right: 0;
  z-index: 1;
  opacity: 0.7;
}
.no-isw-icons:before {
  background-image: none !important;
}
.GraphSidecar:before {
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  background-position: 4px -4px;
  height: 32px;
  width: 32px;
  top: 0px;
  right: 0;
  z-index: 1;
  opacity: 0.7;
}
.GraphVideo:before {
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  background-position: 3px -42px;
  height: 32px;
  width: 32px;
  top: 3px;
  right: 0;
  z-index: 1;
  opacity: 0.7;
}
.jr-insta-thumb {
  padding: 0 !important;
}
.wis-feed-header {
  height: 60px;
  overflow: hidden;
  display: inline-flex;
  margin: 0px 0px !important;
}
.wis-header-info-username {
  margin-bottom: -4px !important;
  margin-top: -4px !important;
  vertical-align: middle;
}
.wis-header-info {
  margin-left: 10px;
  font-size: 17px;
}
.wis-header-neg {
  opacity: 0;
  position: absolute;
  height: 50px;
  width: 50px;
  z-index: 999;
  background-color: black;
}
.wis-header-neg-icon {
  color: white;
  margin-left: 12px;
  margin-top: 11px;
  font-size: 29px;
}
.wis-box {
  height: 50px;
}
.wis-box div {
  vertical-align: top;
  margin-top: 0;
  display: inline-block;
}
.wis-round {
  border-radius: 50px !important;
}
.wis-header-info:hover p {
  cursor: pointer;
  text-decoration: none;
  color: #ed1e24;
}
.wis-header-img:hover .wis-header-neg {
  opacity: 0.5;
  cursor: pointer;
}
