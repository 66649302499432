body {
  --wp--preset--color--black: #000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #fff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --wp--preset--gradient--blush-light-purple: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
  --wp--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --wp--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --wp--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --wp--preset--gradient--electric-grass: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
  --wp--preset--gradient--midnight: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
  --wp--preset--duotone--dark-grayscale: url("#wp-duotone-dark-grayscale");
  --wp--preset--duotone--grayscale: url("#wp-duotone-grayscale");
  --wp--preset--duotone--purple-yellow: url("#wp-duotone-purple-yellow");
  --wp--preset--duotone--blue-red: url("#wp-duotone-blue-red");
  --wp--preset--duotone--midnight: url("#wp-duotone-midnight");
  --wp--preset--duotone--magenta-yellow: url("#wp-duotone-magenta-yellow");
  --wp--preset--duotone--purple-green: url("#wp-duotone-purple-green");
  --wp--preset--duotone--blue-orange: url("#wp-duotone-blue-orange");
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
}
:where(.is-layout-flex) {
  gap: 0.5em;
}
body .is-layout-flow > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}
body .is-layout-flow > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}
body .is-layout-flow > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}
body .is-layout-constrained > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}
body .is-layout-constrained > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}
body .is-layout-constrained > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}
body
  .is-layout-constrained
  > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: var(--wp--style--global--content-size);
  margin-left: auto !important;
  margin-right: auto !important;
}
body .is-layout-constrained > .alignwide {
  max-width: var(--wp--style--global--wide-size);
}
body .is-layout-flex {
  display: flex;
}
body .is-layout-flex {
  flex-wrap: wrap;
  align-items: center;
}
body .is-layout-flex > * {
  margin: 0;
}
:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}
.has-black-color {
  color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-color {
  color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-color {
  color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-color {
  color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-color {
  color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-color {
  color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-color {
  color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-color {
  color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-color {
  color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-color {
  color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-color {
  color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-color {
  color: var(--wp--preset--color--vivid-purple) !important;
}
.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-background-color {
  background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-background-color {
  background-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-background-color {
  background-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-background-color {
  background-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-background-color {
  background-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-background-color {
  background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-background-color {
  background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-background-color {
  background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-background-color {
  background-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-black-border-color {
  border-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-border-color {
  border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-border-color {
  border-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-border-color {
  border-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-border-color {
  border-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-border-color {
  border-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-border-color {
  border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-border-color {
  border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-border-color {
  border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-border-color {
  border-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple
  ) !important;
}
.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan
  ) !important;
}
.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange
  ) !important;
}
.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red
  ) !important;
}
.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray
  ) !important;
}
.has-cool-to-warm-spectrum-gradient-background {
  background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}
.has-blush-light-purple-gradient-background {
  background: var(--wp--preset--gradient--blush-light-purple) !important;
}
.has-blush-bordeaux-gradient-background {
  background: var(--wp--preset--gradient--blush-bordeaux) !important;
}
.has-luminous-dusk-gradient-background {
  background: var(--wp--preset--gradient--luminous-dusk) !important;
}
.has-pale-ocean-gradient-background {
  background: var(--wp--preset--gradient--pale-ocean) !important;
}
.has-electric-grass-gradient-background {
  background: var(--wp--preset--gradient--electric-grass) !important;
}
.has-midnight-gradient-background {
  background: var(--wp--preset--gradient--midnight) !important;
}
.has-small-font-size {
  font-size: var(--wp--preset--font-size--small) !important;
}
.has-medium-font-size {
  font-size: var(--wp--preset--font-size--medium) !important;
}
.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}
.has-x-large-font-size {
  font-size: var(--wp--preset--font-size--x-large) !important;
}
.wp-block-navigation a:where(:not(.wp-element-button)) {
  color: inherit;
}
:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}
.wp-block-pullquote {
  font-size: 1.5em;
  line-height: 1.6;
}
.page-id-658 .page-header {
  display: none;
}
.page-id-658 .container {
  max-width: none !important;
}
.page-id-658 #primary {
  max-width: none !important;
}
.sl-wrapper .sl-navigation button {
  overflow: hidden;
}
#primary {
  max-width: 800px;
  margin: 0 auto;
  float: none;
  width: unset;
}
.page-header {
  max-width: 800px;
  margin: 0 auto;
}
.slider-content .slider-title,
.slider-content .slider-title a {
  display: none;
}
body,
button,
input,
select,
textarea {
  /* font: 400 13px/21px "Comic Sans MS", sans-serif; */
}
#site-description {
  font-size: 22px;
}
.lang-item a {
  display: flex;
}
.lang-item-ru {
  display: none !important;
}
.site-info #site-title a,
.site-info .copyright a {
  color: black;
}
.site-info .social-links ul li a {
  border: 1.5px solid black;
  color: black;
}
#site-branding {
  padding: 0;
}
.site-info {
  background-color: white;
  padding: 0;
}
.social-links ul li {
  margin: 11px 18px 0 0;
}
.site-info .social-links ul li a:hover {
  border-color: black;
}
.fa-twitter:before {
  content: "\f2c6" !important;
}
.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f232" !important;
}
.social-links ul li a {
  line-height: 47px;
}
#content,
.search #content {
  padding: 60px 0 0px;
}
.page-id-995 #primary {
  max-width: none !important;
}
.page-id-658 #primary {
  padding: 0 4%;
}
#site-branding {
  padding: 50px 0;
  white-space: nowrap;
}
.contact-icons {
  display: flex;
  justify-content: space-around;
}
@media only screen and (max-width: 500px) {
  .contact-icons {
    display: block;
  }
}
@font-face {
  font-family: "w3tc";
  src: url("https://semagin.pro/wp-content/plugins/w3-total-cache/pub/fonts/w3tc.eot");
  src: url("https://semagin.pro/wp-content/plugins/w3-total-cache/pub/fonts/w3tc.eot?#iefix")
      format("embedded-opentype"),
    url("https://semagin.pro/wp-content/plugins/w3-total-cache/pub/fonts/w3tc.woff")
      format("woff"),
    url("https://semagin.pro/wp-content/plugins/w3-total-cache/pub/fonts/w3tc.ttf")
      format("truetype"),
    url("https://semagin.pro/wp-content/plugins/w3-total-cache/pub/fonts/w3tc.svg#w3tc")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
.w3tc-icon:before {
  content: "\0041";
  top: 2px;
  font-family: "w3tc";
}
.sticky {
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  gap: 10px;
  z-index: 1000;
}

#site-branding {
  margin: 0 auto;
  padding: 50px 0;
  white-space: nowrap;
  text-align: center;
}

.insta-grid {
  display: flex;
  flex-wrap: wrap;
}

.insta-grid .item {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% / 6 - 10px);
  margin: 5px;
  display: block;
  position: relative;
}

.insta-grid .item .item_content {
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.25s;
  object-fit: cover;
  backface-visibility: hidden;
}

@media (min-width: 481px) and (max-width: 767px) {
  .insta-grid .item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 3 - 10px);
    margin: 5px;
  }
}

@media (max-width: 480px) {
  .insta-grid .item {
    flex: 0 0 calc(100% / 2 - 10px);
    margin: 5px;
  }
}
