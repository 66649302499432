.wis_accounts_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-width: 100%;
  height: 400px;
  max-height: 100%;
  z-index: 1010;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  border: 1px solid #32323280;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.5);
  -moz-box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.5);
  box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.5);
}
.wis_closed {
  display: none !important;
}
.wis_modal_content {
  position: initial;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  padding: 15px 20px 15px 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.wis_modal_header {
  position: initial;
  top: 0;
  left: 0;
  width: auto;
  height: 20px;
  padding: 15px 20px 15px 20px;
  overflow: hidden;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
}
.wis_modal_overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.wis-overlay-spinner {
  background-size: 100px 100px;
  vertical-align: middle;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  position: fixed;
  visibility: hidden;
  z-index: 1001;
}
.is-active {
  visibility: visible !important;
}
.wis-row {
  width: 95%;
  display: flex;
}
.wis-col-1 {
  float: left;
  width: 20%;
}
.wis-col-2 {
  float: left;
  width: 80%;
}
.wis-row-style {
  padding: 10px;
  cursor: pointer;
}
.wis-row-style:hover {
  background-color: #dadada;
}
.wis-col1-style {
  text-align: center;
  vertical-align: middle;
}
.wis-col1-style img {
  border: 1px solid #dadada;
  border-radius: 10px;
  vertical-align: middle;
}
.wis-col2-style {
  font-weight: bold;
  font-size: 14px;
  padding-left: 5px;
}
.wis-col2-style p {
  vertical-align: middle;
}
.wis-page-title {
  text-align: center;
  margin: 20px 0;
  height: 100px;
  transform: translateY(35%);
}
.wis-page-title h1 {
  vertical-align: middle;
  color: white;
  text-shadow: 1px 1px 1px #004769;
}
